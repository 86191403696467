import React from 'react'
import { useSelector} from 'react-redux'

const Header = () => {
  const { headerImage } = useSelector((state) => state.headerImage);
  return (
    <header className='header'>
        <img  src={headerImage?.images?headerImage?.images:'/images/header-image-larger.png'} alt='car running on road' className='header__image'></img>
    </header>
  )
}

export default Header