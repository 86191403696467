import React, { useState, useRef } from 'react';
import PlacesAutocomplete from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RentalForm = () => {
  const [formValues, setFormValues] = useState({
    location: null,
    package: '',
    pickUpDate: '',
    pickUpTime: '',
    mobileNumber: ''
  });

  const pickUpDateInputRef = useRef(null);
  const pickUpTimeInputRef = useRef(null);
  
  const handleWrapperClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  const { location } = useSelector((state) => state.location);

  const today = new Date();
  const formattedToday = today.toLocaleDateString('en-CA');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const randomNumber = Math.floor(Math.random() * 60) + 1;

  const handleSubmit = (e) => {
    e.preventDefault();

    const pickup_lat = formValues.location ? formValues.location.latitude : null;
    const pickup_long = formValues.location ? formValues.location.longitude : null;

    const tripData = {
      trip_type: 2,
      round_trip: 0,
      pickup: formValues.location?.label || '',
      pickup_lat,
      pickup_long,
      pickup_city: formValues.location?.city || formValues.location?.label || '',
      package_type_in_hours: formValues.package,
      trip_date: formValues.pickUpDate,
      trip_time: formValues.pickUpTime,
      phone_number: formValues.mobileNumber,
      ip_address: location?.ip || '8425',
    };


    navigate(`/cabs?trip_type=${tripData?.trip_type}&round_trip=${tripData?.round_trip}&pickup=${tripData?.pickup}&pickup_lat=${tripData?.pickup_lat}&pickup_long=${tripData?.pickup_long}&pickup_city=${tripData?.pickup_city}&drop_city=${tripData?.drop_city}&package_type_in_hours=${tripData?.package_type_in_hours}&trip_date=${tripData?.trip_date}&trip_time=${tripData?.trip_time}&
      phone_number=${tripData?.phone_number}`);
  };


  const handlePlaceSelect = async (value, field) => {
    if (!value) {
      // Handle the case when the value is cleared
      setFormValues(prevValues => ({
        ...prevValues,
        [field]: null, // Clear the field value in formValues
      }));
      return; // Exit the function
    }

    try {
      const locationDetails = await getPlaceDetails(value.value.description);

      if (locationDetails) {
        setFormValues(prevValues => ({
          ...prevValues,
          [field]: {
            label: value.label,
            place_id: value.place_id, // Store the place_id if needed
            latitude: locationDetails.lat,
            longitude: locationDetails.lng,
            city: locationDetails.city,
          },
        }));
      } else {
        console.error('Location details could not be fetched.');
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const getPlaceDetails = async (address) => {
    try {
      const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address: address,
          key: API_KEY
        }
      });

      if (response && response.data && response.data.results && response.data.results[0] && response.data.results[0].geometry) {

        // Extract latitude and longitude
        const { lat, lng } = response.data.results[0].geometry.location;

        // Extract city from address components
        const addressComponents = response.data.results[0].address_components;
        const cityComponent = addressComponents.find(component => component.types.includes('locality'));

        return {
          lat,
          lng,
          city: cityComponent ? cityComponent.long_name : ''
        };
      }
      return null; // Make sure to return null if the expected data isn't found
    } catch (error) {
      return null;
    }
  };

  return (
    <div className='rental-form'>
      <form onSubmit={handleSubmit}>

        <PlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          selectProps={{
            value: formValues.location ? { label: formValues.location.label } : null,
            onChange: (value) => handlePlaceSelect(value, 'location'),
            placeholder: 'Location',
            isClearable: true,
            getOptionLabel: (e) => e.label || '',
            getOptionValue: (e) => e.place_id || '',
            styles: {
              control: (provided) => ({
                ...provided,
                padding: '5px',
                border: '1px solid #000',
                boxShadow: 'none',
                width: '100%',
                marginBottom: '2rem',
                fontSize: '1.4rem',
              }),
              input: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              menu: (provided) => ({
                ...provided,
                fontSize: '1.4rem',  // Match dropdown font size to input
                zIndex: 3
              }),
              option: (provided, state) => ({
                ...provided,
                fontSize: '1.4rem',  // Match font size of options
                backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            },
            components: {
              DropdownIndicator: () => null,  // Removes the dropdown arrow
              IndicatorSeparator: () => null, // Removes the separator
            },
          }}
        />

        <select
          className='input-select'
          name='package'
          value={formValues.package}
          onChange={handleChange}
          required
        >
          <option value='' disabled>Select Package</option>
          <option value='4Hours - 40 km'>4Hours - 40 km</option>
          <option value='6Hours - 60 km'>6Hours - 60 km</option>
          <option value='8Hours - 80km'>8Hours - 80km</option>
          <option value='12Hours - 120km'>12Hours - 120km</option>
        </select>

        <div className='rental-form__date-time'>
          <div className='input-wrapper' onClick={() => handleWrapperClick(pickUpDateInputRef)}>
            <input
              type='date'
              className='input-date'
              name='pickUpDate'
              ref={pickUpDateInputRef}
              min={formattedToday}
              value={formValues.pickUpDate}
              onChange={handleChange}
              required
            />
            <label className={`input-placeholder ${formValues.pickUpDate ? 'input-hidden' : ''}`}>Pick Up Date</label>
            <svg className='input-icon' aria-hidden="true">
              <use href="sprite.svg#icon-calendar"></use>
            </svg>
          </div>
          <div className='input-wrapper' onClick={() => handleWrapperClick(pickUpTimeInputRef)}>
            <input
              type='time'
              className='input-time'
              name='pickUpTime'
              value={formValues.pickUpTime}
              ref={pickUpTimeInputRef}
              onChange={handleChange}
              required
            />
            <label className={`input-placeholder ${formValues.pickUpTime ? 'input-hidden' : ''}`}>Pick Up Time</label>
            <svg className='input-icon' aria-hidden="true">
              <use href="sprite.svg#icon-clock"></use>
            </svg>
          </div>
        </div>

        <input
          className='input-phone'
          placeholder='Mobile Number'
          name='mobileNumber'
          type='tel'
          value={formValues.mobileNumber}
          onChange={handleChange}
          pattern="[0-9]{10}"
          maxLength="10"
          required
        />

        <button type='submit' className='input-submit-button'>Search</button>
      </form>
      <p className='rental-form__paragraph'>Last cab booked from {location?.city || "Ahmedabad"} {randomNumber} sec ago.</p>
    </div>
  );
};

export default RentalForm;
