import React from 'react'

const MainGetApp = () => {
    return (
        <div className='main-getApp'>
            <div className='main-getApp__container'>
            <div className='main-getApp--left'>
                <h2 className='main-getApp__heading'>Find Your <br /> Next Adventure</h2>
                <p className='main-getApp__para'>
                    Need a ride? Let Pulpit be at your service, a one-stop solution for every of cab booking. We offer riders several options with in city taxi oneway taxi booking, outstation cab booking and hourly rentals.
                    <br/> <br/>
                    Your ride awaits at the Pulpit’s convenient rider application.
                    All it takes is just one click.
                </p>
            </div>
            <div className='main-getApp--right'>
            </div>
            </div>
        </div>
    )
}

export default MainGetApp