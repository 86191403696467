import React from 'react'

const ServiceCard = ({ card }) => {
    return (
        <div className='service-card'>
            <div className='service__img-box'>
               <img src={card?.image} alt='wagonr cab' className='service-card__img'></img>
            </div>
            <h2 className='service-card__heading'>{card?.name}</h2>
            <p className='service-card__details'>
                {card?.des}
            </p>
        </div>
    )
}

export default ServiceCard