import React,{useEffect} from 'react'
import Slider from '../../../components/Slider';
import BlogCard from '../../../components/Cards/BlogCard';
import { useSelector, useDispatch } from 'react-redux'
import { getBlogCards } from '../../../store/Blog/BlogAction';

const BlogSection = () => {
  const dispatch = useDispatch();

  const { blogCards} = useSelector((state) => state.blogCards);
   
  useEffect(()=>{
      dispatch(getBlogCards({  "blog_count":9}));
  },[dispatch]);

  return (
    <div className='blogSection'>
       <h1 className='primary__heading margin__bottom-medium'>Blogs</h1>
       <Slider cards={blogCards?.data} cardWidth={35.4} cardMargin={2} component={BlogCard}></Slider>
    </div>
  )
}

export default BlogSection