import React from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import MainPartner from './MainPartner'
import EarnPartner from './EarnPartner'
import Client from '../../layout/Client'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo'

function Partner() {
  const seo = getSEOData('partner');
  return (
    <div className='partner'>     
     <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet> 
       <Navbar page="partner"></Navbar>
       <Header></Header>
       <MainPartner></MainPartner>
       <EarnPartner></EarnPartner>
       <Client></Client>
       <Review></Review>
       <DownloadSection></DownloadSection>
       <Footer></Footer>
    </div>
  )
}

export default Partner