import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import getSEOData from '../../../components/Seo';

const AgentLogin = () => {
    const [formValues, setFormValues] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server, validate, etc.)
        console.log('Form Submitted:', formValues);
    };

    const seo = getSEOData('agentLogin');

    return (
        <div className='login'>
            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />
                <meta name="keywords" content={seo.keywords} />
            </Helmet>
            <div className='login__container'>
            <div className='login__image-container'>
                <img className='login__image' src='./images/agent-login-bg.png' alt='Login background' />
            </div>
            <div className='login__content'>
                <div className='login__form-container'>
                    <img src='./images/logo-small.png' className='login__logo' alt='Logo' />
                    <form className='login__form' onSubmit={handleSubmit} autoComplete='on'>
                        <div className='login__heading'>Are you an Agent? Login</div>
                        <div className='login__form-group'>
                            <div className='login__input-wrapper'>
                                <input
                                    className='input-email'
                                    placeholder='Email ID'
                                    name='email'
                                    type='email'
                                    value={formValues.email}
                                    onChange={handleChange}
                                    autoComplete='email'
                                    required
                                />
                            </div>
                            <div className='login__input-wrapper'>
                                <input
                                    className='input-password'
                                    placeholder='Password'
                                    name='password'
                                    type='password'
                                    value={formValues.password}
                                    onChange={handleChange}
                                    autoComplete='current-password'
                                    required
                                />
                            </div>
                        </div>
                        <div className='login__form-actions'>
                            <div className='login__remember-me'>
                                <input
                                    type='checkbox'
                                    className='input-checkbox'
                                    id='remember'
                                    autoComplete='on'  // Ensures browser auto-fill can remember credentials
                                />
                                <label htmlFor='remember'>Remember me</label>
                            </div>
                            <button type='submit' className='login__button input-submit-button'>Sign In</button>
                        </div>
                    </form>
                    <div className='login__redirect'>
                        <svg className='login__redirect-icon' aria-hidden="true">
                            <use href="sprite.svg#icon-password"></use>
                        </svg>
                        <div className='login__redirect-links'>
                            <div className='login__redirect-link'>
                                Forgot Password? <a href='/forgot-password'>Redirect</a>
                            </div>
                            <div className='login__redirect-para'>We will send a password reset link to your email address.</div>
                        </div>
                    </div>
                    <div className='login__redirect login__redirect--secondary'>

                        <svg className='login__redirect-icon' aria-hidden="true">
                            <use href="sprite.svg#icon-login-user"></use>
                        </svg>

                        <div className='login__redirect-links'>
                            <div className='login__redirect-link'>
                                Are you an Member? <a href='/member-login'>Login Here</a>
                            </div>
                            <div className='login__redirect-para'>You will be taken to the member interface.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default AgentLogin;