import React from 'react'

const ClientCard = ({card}) => {
  return (
    <div className='clientCard'>
      <img src={card?.image} alt='client of pulpit' className='clientCard__img'></img>
    </div>
  )
}

export default ClientCard