import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { submitBusinessForm } from '../../../store/Business/BusinessAction';
import { businessAction } from '../../../store/Business/BusinessReducer';
import { toast } from 'react-toastify';
const BusinessForm = () => {
  const [formValues, setFormValues] = useState({
    phone: '',
    email: '',
    name: '',
    services: [],
    comments: '',
  });

  const {error, isSubmit } = useSelector((state)=> state.business);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormValues(prevValues => {
        const updatedServices = checked 
          ? [...prevValues.services, name]  // Add service if checked
          : prevValues.services.filter(service => service !== name);  // Remove service if unchecked
        return { ...prevValues, services: updatedServices };
      });
    } else {
      setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    }
  };

  useEffect(() => {
    if (isSubmit) {
      toast.success('Application received! We will contact you soon.', {
        position: 'top-right',
        style: { fontSize: '12px' }   
      });
      setFormValues({
        phone: '',
        email: '',
        name: '',
        services: [],
        comments: '',
      });
      dispatch(businessAction.clearSubmit());
    }
    if (error) {
      toast.error('There was an issue. Please try again later.', {
        position: 'top-right' ,
        style: { fontSize: '12px' } 
      });
      dispatch(businessAction.clearError());
    }
  }, [dispatch,isSubmit, error]);  
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if at least one service is selected
    if (formValues.services.length === 0) {
      alert('Please select at least one service option.');
      return;
    }
    
    dispatch(submitBusinessForm(formValues));
  };

  return (
    <div className='business-form'>
      <div className='business-form__heading'>Great things start with
      reliable <span className='color-secondary'>RIDE</span></div>
      <div className='business-form__para'>We will reach out to you</div>
      <form onSubmit={handleSubmit} className='business-form__form'>

        {/*Name Input */}
        <input 
          className='input-textarea' 
          placeholder='Name' 
          name='name' 
          type='text' 
          value={formValues.name} 
          onChange={handleChange} 
          required 
        />

        {/* Mobile Number Input */}
        <input 
          className='input-phone' 
          placeholder='Mobile Number' 
          name='phone' 
          type='tel' 
          value={formValues.phone} 
          onChange={handleChange} 
          pattern="[0-9]{10}" 
          maxLength="10" 
          required 
        />

        {/* Email Input */}
        <input 
          className='input-email' 
          placeholder='Email Address' 
          name='email' 
          type='email' 
          value={formValues.email} 
          onChange={handleChange} 
          required 
        />

        {/* Checkboxes for services */}
        <div className="business-form__checkbox-box">
          <label className='input-checkbox-label'>
            <input
              type="checkbox"
              name="Employee transportation"
              checked={formValues.services.includes('Employee transportation')}
              onChange={handleChange}
              className='input-checkbox'
            />
            Employee transportation
          </label>
          <label className='input-checkbox-label'>
            <input
              type="checkbox"
              name="On-demand Service"
              checked={formValues.services.includes('On-demand Service')}
              onChange={handleChange}
              className='input-checkbox'
            />
            On-demand Service
          </label>
          <label className='input-checkbox-label'>
            <input
              type="checkbox"
              name="Outstation Biz Rides"
              checked={formValues.services.includes('Outstation Biz Rides')}
              onChange={handleChange}
              className='input-checkbox'
            />
            Outstation Biz Rides
          </label>
          <label className='input-checkbox-label'>
            <input
              type="checkbox"
              name="Customisable Plan"
              checked={formValues.services.includes('Customisable Plan')}
              onChange={handleChange}
              className='input-checkbox'
            />
            Customisable Plan
          </label>
        </div>

        {/* Comments Input */}
        <input 
          className='input-textarea' 
          placeholder='Comment' 
          name='comments' 
          type='text' 
          value={formValues.comments} 
          onChange={handleChange} 
          required 
        />

        {/* Submit Button */}
        <button type='submit' className='input-submit-button'>Submit</button>
      </form>
    </div>
  );
};

export default BusinessForm;