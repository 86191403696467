import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({page}) => {
  const [login, setLogin] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);

  const handleClick = ()=>{
    setOpenNavbar(!openNavbar);
  }

  useEffect(() => {
    if (openNavbar) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [openNavbar]);

  useEffect(()=>{
    setLogin(false);
  },[]);
   
  return (
    <nav className='navbar'>
      <div className='navbar__box'>
        <Link to="/" className="navbar__logo-link">
          <img src="/images/logo-big.png" alt="MyCompany Logo - Cab Service" className="navbar__logo" />
        </Link>
        <ul className="navbar__menu" aria-label="Main navigation">
          <li className="navbar__menu-item">
            <Link to="/" className={`navbar__menu-link ${page === "home" ? "navbar__menu__active" : ""}`}>Rider</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/partner" className={`navbar__menu-link ${page === "partner" ? "navbar__menu__active" : ""}`}>Partner</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/business" className={`navbar__menu-link ${page === "business" ? "navbar__menu__active" : ""}`}>Business</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/blogs" className={`navbar__menu-link ${page === "blog" ? "navbar__menu__active" : ""}`}>Blogs</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/about-us" className={`navbar__menu-link ${page === "about-us" ? "navbar__menu__active" : ""}`}>About Us</Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/support" className={`navbar__menu-link ${page === "support" ? "navbar__menu__active" : ""}`}>Support</Link>
          </li>
        </ul>
        {openNavbar && <div className='navbar__mobile'>
          <ul className="navbar__mobile-menu" aria-label="Main navigation">
          <li className="navbar__mobile-menu-item">
            <Link to="/" className={`navbar__mobile-menu-link ${page === "home" ? "navbar__mobile-menu__active" : ""}`}>Rider</Link>
          </li>
          <li className="navbar__mobile-menu-item">
            <Link to="/partner" className={`navbar__mobile-menu-link ${page === "partner" ? "navbar__mobile-menu__active" : ""}`}>Partner</Link>
          </li>
          <li className="navbar__mobil-menu-item">
            <Link to="/business" className={`navbar__mobile-menu-link ${page === "business" ? "navbar__mobile-menu__active" : ""}`}>Business</Link>
          </li>
          <li className="navbar__mobile-menu-item">
            <Link to="/blogs" className={`navbar__mobile-menu-link ${page === "blog" ? "navbar__mobile-menu__active" : ""}`}>Blogs</Link>
          </li>
          <li className="navbar__mobile-menu-item">
            <Link to="/about-us" className={`navbar__mobile-menu-link ${page === "about-us" ? "navbar__mobile-menu__active" : ""}`}>About Us</Link>
          </li>
          <li className="navbar__mobile-menu-item">
            <Link to="/support" className={`navbar__mobile-menu-link ${page === "support" ? "navbar__mobile-menu__active" : ""}`}>Support</Link>
          </li>
          <li className="navbar__mobile-menu-item">
          {login ? <Link to="/profile" className="navbar__mobile-action-link navbar__mobile-profile">
              <img src="/images/profile.png" alt="John Doe's Profile" className="navbar__mobile-profile-pic" />
              <span className="navbar__mobile-profile-name">John Doe</span>
            </Link> :
              <Link to="/member-login" className="navbar__mobile-action-link navbar__mobile-login">
                <svg className='navbar__mobile-login-icon' aria-hidden="true">
                  <use href="/sprite.svg#icon-enter"></use>
                </svg>
                <span className='navbar__mobile-login-text'>Login</span>
              </Link>
            }
          </li>
        </ul>          
          </div>}
        <ul className="navbar__action">
          <li className="navbar__action-item">
            <Link to='/get-app' className='navbar__action-link navbar__app'>
              <svg className='navbar__app-icon' aria-hidden="true">
                <use href="/sprite.svg#icon-smartphone"></use>
              </svg>
              <span className='navbar__app-text'>Get App</span>
            </Link>
          </li>
          <li className="navbar__action-item">
            <a href="tel:+918052080501" className="navbar__action-link navbar__contact">
              <svg className='navbar__contact-icon' aria-hidden="true">
                <use href="/sprite.svg#icon-calling"></use>
              </svg>
              <span className='navbar__contact-text'>+91 80520 80501</span>
            </a>
          </li>
          <li className="navbar__action-item">
            {login ? <Link to="/profile" className="navbar__action-link navbar__profile">
              <img src="/images/profile.png" alt="John Doe's Profile" className="navbar__profile-pic" />
              <span className="navbar__profile-name">John Doe</span>
              <svg className='navbar__profile-icon' aria-hidden="true">
                <use href="/sprite.svg#icon-down-chevon"></use>
              </svg>
            </Link> :
              <Link to="/member-login" className="navbar__action-link navbar__login">
                <svg className='navbar__login-icon' aria-hidden="true">
                  <use href="/sprite.svg#icon-enter"></use>
                </svg>
                <span className='navbar__login-text'>Login</span>
              </Link>
            }
            {!openNavbar && <img className='navbar__icon' src="/images/icon-navbar-mobile.svg" alt='pulpit navbar icon' onClick={handleClick}/>}
            {openNavbar &&  <svg className='navbar__icon' aria-hidden="true" onClick={handleClick}>
                <use href="/sprite.svg#icon-close"></use>
              </svg> }
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;