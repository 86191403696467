import { createSlice } from "@reduxjs/toolkit"
import { getDynamicStats, getDynamicHeaderImage } from "./DynamicDataAction";


const dynamicStatsSlice = createSlice({
    name: 'dynamicStats',
    initialState: { loading: true, error: false, stats: []},
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDynamicStats.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDynamicStats.fulfilled, (state, action) => {
                state.stats = action?.payload?.data?.data?.statsNumbers;
                state.loading = false;
                state.error = false;
            })
            .addCase(getDynamicStats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


const dynamicHeaderImageSlice = createSlice({
    name: 'dynamicHeaderImageSlice',
    initialState: { loading: true, error: false, headerImage: {}},
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDynamicHeaderImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDynamicHeaderImage.fulfilled, (state, action) => {
                state.headerImage = action?.payload?.data?.data;
                state.loading = false;
                state.error = false;
            })
            .addCase(getDynamicHeaderImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

const dynamicStatsAction = dynamicStatsSlice.actions;
const dynamicHeaderImageAction = dynamicHeaderImageSlice.actions;

export {dynamicStatsAction, dynamicStatsSlice, dynamicHeaderImageAction, dynamicHeaderImageSlice};