import React from 'react'

const EarnPartner = () => {
    return (
        <div className='earn-partner'>
            <div className='earn-partner__container'>
            <div className='earn-partner__box1'>
                    <div className='earn-partner__content'>
                        <h1 className='primary__heading'>Earn with <span className='color-secondary'>Pulpit</span>
                        </h1>
                        <p className='paragraph'>Register on Pulpit and boost your business as a partner. This is an opportunity for drivers, agents, and travel companies alike! Respond and offer your taxi service to customers who search for “cab near me” on Pulpit at your convenience. Register as a partner with one car, no car, or multiple cars and boost your revenue.
                        </p>
                    </div>
                </div>
                <div className='earn-partner__box2'>
                    {/* <img src='' alt='pulpit mobile locai' ></img> */}
                </div>
            </div>
        </div>
    )
}

export default EarnPartner