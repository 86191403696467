import React from 'react'
import { Link } from 'react-router-dom';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const BlogCard = ({card}) => {
  return (
    <div className='blogCard'>
        <div className='blogCard__img-box'>
            <img src={card?.images} alt='pulpit blog card' className='blogCard__img'></img>
        </div>
        <div className='blogCard__date'>{card?.created_at && formatDate(card.created_at)}</div>
        <div className='blogCard__des'>{card?.short_description}</div>
        <Link to={`/blog/${card?.id}`}> <button  className='blogCard__button'>Read More</button></Link>
    </div>
  )
}

export default BlogCard