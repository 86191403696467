import React from 'react';
import Slider from '../../components/Slider';
import ReviewCard from '../../components/Cards/ReviewCard'
import { testimonial } from '../../components/data';
  
  const Review = () => {
    return (
      <div className='review'>
         <h1 className='primary__heading margin__bottom-medium align-center'>What clients are Saying</h1>
         <Slider cards={testimonial} cardWidth={33} cardMargin={2} component={ReviewCard}></Slider>
      </div>
    )
  }

  
export default Review