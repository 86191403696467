import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getCabs = createAsyncThunk("cabCabs", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/get_trip_fare`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const getCustomerFAQ = createAsyncThunk("customerFAQ", async (data,{rejectWithValue}) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/website/get_customer_faqs`);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const bookNow = createAsyncThunk("bookNow", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/book_now`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const createOrder = createAsyncThunk("createOrder", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/createOrder`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const applyCoupon = createAsyncThunk("applyCoupon", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/apply_coupon`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});


export {getCabs, bookNow, createOrder,applyCoupon,getCustomerFAQ}