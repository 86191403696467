import React from 'react'

const BenefitGetApp = () => {
  return (
    <div className='benefit-getApp'>
      <h2 className='primary__heading--white align-center'>Benefits of Our Booking App</h2>
      <div className='benefit-getApp__container'>
        <div className='benefit-getApp__box'>
          <div className='benefit-getApp__icon-box'>
            <svg className='benefit-getApp__icon' aria-hidden="true">
              <use href="sprite.svg#icon-book"></use>
            </svg>
          </div>
          <h3 className='benefit-getApp__title'>24/7 Booking</h3>
          <p className='benefit-getApp__description'>Available anytime, anywhere, with mobile and desktop access.</p>
        </div>

        <div className='benefit-getApp__box'>
          <div className='benefit-getApp__icon-box'>
            <svg className='benefit-getApp__icon' aria-hidden="true">
              <use href="sprite.svg#icon-real-time"></use>
            </svg>
          </div>
          <h3 className='benefit-getApp__title'>Real-Time Availability</h3>
          <p className='benefit-getApp__description'>Live updates and instant confirmation.</p>
        </div>

        <div className='benefit-getApp__box'>
          <div className='benefit-getApp__icon-box'>
            <svg className='benefit-getApp__icon' aria-hidden="true">
              <use href="sprite.svg#icon-customer-support"></use>
            </svg>
          </div>
          <h3 className='benefit-getApp__title'>Customer Support</h3>
          <p className='benefit-getApp__description'>In-App assistance, FAQ, and Help Sections.</p>
        </div>

        <div className='benefit-getApp__box'>
          <div className='benefit-getApp__icon-box'>
            <svg className='benefit-getApp__icon' aria-hidden="true">
              <use href="sprite.svg#icon-payment"></use>
            </svg>
          </div>
          <h3 className='benefit-getApp__title'>Payment Flexibility</h3>
          <p className='benefit-getApp__description'>Multiple payment options and secure transactions.</p>
        </div>
      </div>
    </div>
  )
}

export default BenefitGetApp