import React from 'react'
import PartnerBenefit from './PartnerBenefit'
import PartnerForm from './PartnerForm'

const MainPartner = () => {
    return (
        <main className='partner-main'>
            <div className='partner-main__box'>
                <div className='partner-main__box--left'>
                    <PartnerBenefit />
                </div>
                <div className='partner-main__box--right'>
                    <PartnerForm></PartnerForm>
                    <div className='partner-main__content'>
                        <div className='partner-main__heading'>Join Hands with <span className='color-secondary'>Pulpit</span>
                            <span className='break'> As Partners</span></div>
                        <div className='partner-main__para'>
                            We welcome all new drivers, taxi service providers, independent agents, and travel agencies to sign up as partners.
                        </div>
                        <div className='partner-main__para'>
                            Leverage your vehicles and give a ride to make money with Pulpit’s
                            Partner. All you need to do it download the app and sign up with one vehicle, multiple vehicles, or even no vehicle!
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MainPartner