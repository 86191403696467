import React from 'react'
import BusinessBenefit from '../BusinessBenefit'
import BusinessForm from '../BusinessForm'

const MainBusiness = () => {
    return (
        <main className='business-main'>
            <div className='business-main__box'>
                <div className='business-main__box--left'>
                    <BusinessBenefit />
                </div>
                <div className='business-main__box--right'>
                    <BusinessForm />
                    <div className='business-main__img-box'>
                        <img src='./images/business-img.png' alt='pulpit business' className='business-main__img'></img>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MainBusiness