import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// for new Order
const getBlogCards = createAsyncThunk("blogCards", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/get_blogs`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const getBlog = createAsyncThunk("blog", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/get_blogs`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {getBlogCards,getBlog}