import React from 'react';
import { links } from '../../../../components/data';
const PartnerForm = () => {
  return (
    <div className='partner-form'>
      <div className='partner-form__heading'>Be a Partner</div>
        <a href={links?.googlePlayDriver} target='_blank'  rel='noreferrer'>
          <button className='input-submit-button'>Partner</button>
        </a>
    </div>
  );
};

export default PartnerForm;
