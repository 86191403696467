import { createSlice } from "@reduxjs/toolkit"
import { submitBusinessForm } from "./BusinessAction";


const businessSlice = createSlice({
    name: 'business',
    initialState: { loading: true, error: false, businessForm: '', isSubmit: false},
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
        clearSubmit: (state) => {
            state.isSubmit = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitBusinessForm.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitBusinessForm.fulfilled, (state, action) => {
                state.businessForm = action?.payload?.message;
                state.isSubmit = true;
                state.loading = false;
                state.error = false;
            })
            .addCase(submitBusinessForm.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    }
});


const businessAction = businessSlice.actions;

export {businessAction, businessSlice};