import React from 'react'
// import CabFilter from '../CabFilter'
import CabBox from '../CabCardBox'


const MainCab = ({tripDetails}) => {
  return (
    <div className='mainCab'>
        <div className='mainCab__container'>
            {/* <div className='mainCab__container--left'>
              <CabFilter/>
            </div> */}
            <div className='mainCab__container--right'>
                <CabBox tripDetails={tripDetails}></CabBox>
            </div>
        </div>
    </div>
  )
}

export default MainCab