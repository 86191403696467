import {configureStore} from '@reduxjs/toolkit';
import  {blogCardSlice, blogSlice} from './Blog/BlogReducer'
import { userLocationSlice } from './UserLocation/UserReducer';
import { dynamicStatsSlice, dynamicHeaderImageSlice } from './DynamicData/DynamicDataReducer';
import { cabSlice } from './Cab/CAbReducer';
import { businessSlice } from './Business/BusinessReducer';
import { subscribeSlice } from './SubScribe/SubScribeReducer';

const Store = configureStore({
    reducer:{
      blogCards: blogCardSlice.reducer,
      blog: blogSlice.reducer,
      location: userLocationSlice.reducer,
      stats: dynamicStatsSlice.reducer,
      headerImage: dynamicHeaderImageSlice.reducer,
      cab: cabSlice.reducer,
      business: businessSlice.reducer,
      subscribe: subscribeSlice.reducer
    }
});
export default Store;