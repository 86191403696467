import React from 'react';

const StarRating = ({ rating, totalStars = 5 }) => {
  const getStarType = (index) => {
    if (rating >= index + 1) {
      return 'full'; // Full star
    } else if (rating >= index + 0.5) {
      return 'half'; // Half star
    } else {
      return 'empty'; // Empty star
    }
  };

  return (
    <div className='star-rating'>
      {[...Array(totalStars)].map((_, index) => {
        const starType = getStarType(index);

        return (
          <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
            {/* Full star as background */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='#e4e5e9'  // Empty star color for background
              className='star'
              width='24'
              height='24'
            >
              <path d='M22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 5.01L5.82 22 12 18.77 18.18 22l-1.63-7.75L22 9.24z' />
            </svg>

            {/* Conditionally render filled stars */}
            {starType === 'full' && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='#E05A49'  // Full star color
                className='star'
                width='24'
                height='24'
                style={{ position: 'absolute', top: 0, left: 0 }}
              >
                <path d='M22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 5.01L5.82 22 12 18.77 18.18 22l-1.63-7.75L22 9.24z' />
              </svg>
            )}

            {starType === 'half' && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='#E05A49'  // Half star fill color
                className='star'
                width='24'
                height='24'
                style={{ position: 'absolute', top: 0, left: 0, clipPath: 'inset(0 50% 0 0)' }}
              >
                <path d='M22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 5.01L5.82 22 12 18.77 18.18 22l-1.63-7.75L22 9.24z' />
              </svg>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;
