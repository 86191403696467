function getSEOData(pageName) {
    const seoData = {
      home: {
        title: "Pulpit - Your One-Stop Cab Booking Solution",
        description: "Book cabs easily with Pulpit for in-city, outstation trips, or hourly rentals. Our convenient app connects you to reliable rides in just one click.",
        keywords: "cab booking, oneway taxi, outstation cabs, hourly rentals, Pulpit cabs"
      },
      partner: {
        title: "Join Pulpit as a Partner - Drive and Earn",
        description: "Become a Pulpit Partner! Register as a driver, independent agent, or taxi service provider. Drive and earn with flexible cab booking options.",
        keywords: "Pulpit partner, driver signup, taxi service provider, earn with Pulpit"
      },
      business: {
        title: "Pulpit for Business - Efficient Corporate Travel Solutions",
        description: "Streamline your company’s travel needs with Pulpit for Business. Manage employee rides, travel itineraries, and cost-effective cab solutions.",
        keywords: "corporate cab booking, business travel, company cab service, Pulpit for business"
      },
      about: {
        title: "About Pulpit - Your Cab is a Click Away",
        description: "Need a ride? Pulpit offers in-city, outstation, and hourly cab rentals. Learn more about our mission to make cab booking as easy as one click.",
        keywords: "about Pulpit, cab booking service, Pulpit mission, on-demand taxi"
      },
      support: {
        title: "Pulpit Support - We're Here to Help",
        description: "Need help with cab bookings, payments, or app issues? Contact Pulpit Support for assistance with your queries and enjoy a hassle-free experience.",
        keywords: "Pulpit support, contact Pulpit, help with cab booking, customer support"
      },
      blog: {
        title: "Pulpit Blog - News, Updates, and Travel Tips",
        description: "Read the latest updates, travel tips, and industry trends on the Pulpit blog. Stay informed and make your cab booking experience even better.",
        keywords: "Pulpit blog, travel tips, industry updates, cab booking news"
      },
      app: {
        title: "Download the Pulpit App - Easy Cab Booking at Your Fingertips",
        description: "Download the Pulpit app for seamless cab booking. Whether for in-city trips or outstation travel, the Pulpit app offers easy and fast ride bookings.",
        keywords: "Pulpit app, download cab app, taxi booking app, fast cab booking"
      },
      agentLogin: {
        title: "Agent Login - Access Your Pulpit Partner Dashboard",
        description: "Login to your Pulpit Partner account to manage bookings, track earnings, and update vehicle details. Enjoy easy access to your driver or agent profile.",
        keywords: "Pulpit agent login, driver login, partner dashboard, manage bookings"
      },
      memberLogin: {
        title: "Member Login - Access Your Pulpit Account",
        description: "Login to your Pulpit account to manage your rides, view booking history, and update your profile details. Enjoy a personalized cab booking experience.",
        keywords: "Pulpit member login, account login, cab booking history, profile management"
      },
      forgotPassword: {
        title: "Forgot Password - Reset Your Pulpit Account Password",
        description: "Reset your Pulpit account password quickly and securely. Regain access to your profile and manage your bookings with ease.",
        keywords: "forgot password, reset Pulpit password, account recovery, password reset"
      },
      default: {
        title: "Pulpit - Cab Booking Made Easy",
        description: "Welcome to Pulpit, your go-to cab booking platform for in-city, outstation, and hourly cab rentals. Enjoy easy and quick ride bookings with Pulpit.",
        keywords: "cab booking, taxi service, outstation cabs, Pulpit rides"
      }
    };
  
    return seoData[pageName] || seoData.default;
  }
  
  export default getSEOData;  