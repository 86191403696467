import { createSlice } from "@reduxjs/toolkit"
import { getUserLocation } from "./UserLocationAction";

const userLocationSlice = createSlice({
    name: 'userLocation',
    initialState: { loading: true, error: false, location: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserLocation.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserLocation.fulfilled, (state, action) => {
                state.location = action?.payload?.data;
                state.loading = false;
                state.error = false;
            })
            .addCase(getUserLocation.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            });
    }
});

const userLocationAction = userLocationSlice.actions;

export {userLocationAction, userLocationSlice};