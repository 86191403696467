import { createSlice } from "@reduxjs/toolkit"
import { subscribeNewsletter } from "./SubScribeAction";


const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState: { loading: true, error: false, newsletter: '', isSubmit: false},
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
        clearSubmit: (state) => {
            state.isSubmit = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscribeNewsletter.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscribeNewsletter.fulfilled, (state, action) => {
                state.newsletter = action?.payload;
                state.isSubmit = true;
                state.loading = false;
                state.error = false;
            })
            .addCase(subscribeNewsletter.rejected, (state, action) => {
                state.newsletter = action?.payload;
                state.loading = false;
                state.error = true;
            });
    }
});


const subscribeAction = subscribeSlice.actions;

export {subscribeAction, subscribeSlice};