import React from 'react'

const VideoSection = () => {
    return (
        <div className='videoSection'>
            <div className='videoSection__container'>
                <div className='videoSection__box1'>
                    <iframe
                        className="videoSection__iframe"
                        src="https://www.youtube.com/embed/XVtRWieN4IY?autoplay=1&mute=1&loop=1&playlist=XVtRWieN4IY"
                        title="Pulpit Cab Service Intro video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                    >
                    </iframe>
                </div>
                <div className='videoSection__box2'>
                    <div className='videoSection__content'>
                        <h1 className='primary__heading'>Follow us on <span className='color-secondary'>YouTube</span>
                            <span className='break'> For Exclusive Offers!</span></h1>
                        <p className='paragraph'>Need a ride? Let Pulpit be at your service, a one-stop solution for every type of cab booking. We offer riders several options with in-city oneway taxi booking, outstation cab booking, and hourly rentals. Your ride awaits at the Pulpit’s convenient rider application. All it takes is just one click.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoSection