import React from 'react'

const BusinessBenefit = () => {
    return (
        <div className='business-benefit'>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-invoice"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        Instant Invoicing
                    </div>
                    <div className='business-benefit__detail-para'>
                        Get an instant quote and invoice after
                        specifying the type and number of
                        vehicles required.
                    </div>
                </div>
            </div>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-pickup"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        Timely Pickup & Drop Off
                    </div>
                    <div className='business-benefit__detail-para'>
                        We support your business by ensuring
                        that your employees never
                        miss a beat at work.

                    </div>
                </div>
            </div>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-booking"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        Flexible Booking
                    </div>
                    <div className='business-benefit__detail-para'>
                        Book over the phone or website with
                        flexibility in pickup & dropoff location,
                        booking time and type of vehicle.
                    </div>
                </div>
            </div>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-management"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        Overall Management
                    </div>
                    <div className='business-benefit__detail-para'>
                        Smooth end-to-end corporate employee
                        cab management services, from booking
                        to pickup, dropoff, and invoicing.
                    </div>
                </div>
            </div>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-gear"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        Customisable Service
                    </div>
                    <div className='business-benefit__detail-para'>
                        We accommodate your special needs
                        to give your business and employees the
                        best-in-class business taxi service.
                    </div>
                </div>
            </div>
            <div className='business-benefit__card'>
                <div className='business-benefit__icon-box'>
                    <svg className='business-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-commission"></use>
                    </svg>
                </div>
                <div className='business-benefit__detail'>
                    <div className='business-benefit__detail-heading'>
                        24/7 service
                    </div>
                    <div className='business-benefit__detail-para'>
                        Our customer service is open to feedback
                        and queries all day, every day, to meet
                        all your commutation needs.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessBenefit