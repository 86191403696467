import React from 'react'

const TeamCard = ({card}) => {
    return (
        <div className='teamCard'>
            <div className='teamCard__img-box'>
                <img src={card?.image} alt='team person' className='teamCard__img'></img>
            </div>
            <div className='teamCard__heading'>{card?.name}</div>
            <div className='teamCard__para'>{card?.role}</div>
        </div>
    )
}

export default TeamCard