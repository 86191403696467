import React from 'react'

const PartnerBenefit = () => {
    return (
        <div className='partner-benefit'>
            <div className='partner-benefit__card'>
                <div className='partner-benefit__icon-box'>
                    <svg className='partner-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-commission"></use>
                    </svg>
                </div>
                <div className='partner-benefit__detail'>
                    <div className='partner-benefit__detail-heading'>
                        No Commissions
                    </div>
                    <div className='partner-benefit__detail-para'>
                        We won’t ask for a cut from your keep. Just pay for monthly
                        subscriptions and take as many cab bookings as you wish.
                    </div>
                </div>
            </div>
            <div className='partner-benefit__card'>
                <div className='partner-benefit__icon-box'>
                    <svg className='partner-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-support"></use>
                    </svg>
                </div>
                <div className='partner-benefit__detail'>
                    <div className='partner-benefit__detail-heading'>
                        24/7 driver support
                    </div>
                    <div className='partner-benefit__detail-para'>
                        We’re here to help your business grow and protect you
                        when you drive with us.
                    </div>
                </div>
            </div>
            <div className='partner-benefit__card'>
                <div className='partner-benefit__icon-box'>
                    <svg className='partner-benefit__icon' aria-hidden="true">
                        <use href="sprite.svg#icon-driver"></use>
                    </svg>
                </div>
                <div className='partner-benefit__detail'>
                    <div className='partner-benefit__detail-heading'>
                        Decide when you drive
                    </div>
                    <div className='partner-benefit__detail-para'>
                        Become a driver with Pulpit full-time or part-time and
                        <br/>drive whenever you wish.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerBenefit