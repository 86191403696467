import React,{useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDynamicStats } from '../../../store/DynamicData/DynamicDataAction'

const StatisticSection = () => {
  const dispatch = useDispatch();

    const {  stats } = useSelector((state) => state.stats);

    useEffect(()=>{
        dispatch(getDynamicStats({}));
    },[dispatch])

  return (
    <div className='statisticSection'>
        <div className='statisticSection__container'>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-customers"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Customers</div>
               <div className='statisticSection__num'>{parseInt(stats?.users/1000)||33}k</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-review"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>5 Star Rating</div>
               <div className='statisticSection__num'>{parseInt(stats?.ratings/1000)||10}k</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-customers"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Download</div>
               <div className='statisticSection__num'>{parseInt(stats?.downloads/1000)||13}k</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-partners"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Partners</div>
               <div className='statisticSection__num'>{parseInt(stats?.likes/1000)||68}k</div>
           </div>
        </div>
    </div>
  )
}

export default StatisticSection