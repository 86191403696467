import React, { useEffect} from 'react';
import CabCard from '../../../components/Cards/CabCard';
import { getCabs } from '../../../store/Cab/CabAction';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../../components/Loading';
const CabBox = ({tripDetails}) => {
  const dispatch = useDispatch();
  const { loading, cabs } = useSelector((state) => state.cab);

  useEffect(() => {
    dispatch(getCabs(tripDetails));
  }, [dispatch, tripDetails]);

  return (
    <> {loading ? <Loading></Loading> : <div className='cabCardBox'>
      <div className='cabCardBox__para'>
        Showing {cabs?.length||0} cabs from {tripDetails?.pickup_city} {tripDetails?.trip_type!==2 && `to ${tripDetails?.drop_city}`}
      </div>
      <div className='cabCardBox__container'>
        {cabs?.length > 0 &&
          cabs.map((cab) => {
            return <CabCard key={cab.id} showPrice={true} cab={cab} />;
          })
        }
      </div>
    </div>}</>
  );
}

export default CabBox;