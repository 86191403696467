import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFAQ } from '../../../store/Cab/CabAction';

const CustomerFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const dispatch = useDispatch();

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const { customerFAQ } = useSelector((state) => state.cab);

  useEffect(() => {
    dispatch(getCustomerFAQ({}));
  }, [dispatch]);

  return (
    <div className="faq">
      <h2 className="faq__heading">Frequently Asked Questions (FAQs)</h2>
      {Array.isArray(customerFAQ) && customerFAQ?.map((faq, index) => (
        <div key={index} className="faq__item">
          <div className="faq__question" onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className={`faq__icon ${activeIndex === index ? 'faq__icon--active' : ''}`}>
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>
          {activeIndex === index && (
            <div className="faq__answer" dangerouslySetInnerHTML={{ __html: faq.answers }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomerFAQ;