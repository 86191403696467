const loadGoogleMapsScript = (apiKey) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
};

const loadZohoChatScript = () => {
    // Define $zoho properly
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: "siq539a555b23cf21fa4150372bdb7b981cc61d7067c7f741059db5fdf1e6a35c30",
      values: {},
      ready: function () {}
    };
  
    // Create script element
    const d = document;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.id = "zsiqscript";
    s.defer = true;
    s.src = "https://salesiq.zohopublic.in/widget";
  
    // Insert script into the DOM
    const t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  };

export {loadGoogleMapsScript, loadZohoChatScript};