import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const subscribeNewsletter = createAsyncThunk("subscribeNewsletter", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/subscribe_newsletter`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {subscribeNewsletter}