import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../../layout/Navbar';
import Review from '../../layout/Review';
import DownloadSection from '../../layout/downloadSection';
import Footer from '../../layout/Footer';
// import UpdateCabForm from './UpdateCabForm';
import MainCab from './MainCab';
import CustomerFAQ from './CustomerFAQ';

const Cab = () => {
  const { location } = useSelector((state) => state.location);
  const { search } = useLocation();  // gets the search part of the URL

  // Memoize the queryParams object
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  // Memoize the tripDetails object
  const tripDetails = useMemo(() => ({
    trip_type: parseInt(queryParams.get('trip_type')) || '',
    round_trip: parseInt(queryParams.get('round_trip')) || '',
    pickup: queryParams.get('pickup') || '',
    drop: queryParams.get('drop') || '',
    pickup_lat: Number(parseFloat(queryParams.get('pickup_lat'))) || '',
    pickup_long: Number(parseFloat(queryParams.get('pickup_long'))) || '',
    drop_lat: Number(parseFloat(queryParams.get('drop_lat'))) || '',
    drop_long: Number(parseFloat(queryParams.get('drop_long'))) || '',
    pickup_city: queryParams.get('pickup_city') || '',
    drop_city: queryParams.get('drop_city') || '',
    trip_date: queryParams.get('trip_date') || '',
    trip_time: queryParams.get('trip_time') || '',
    phone_number: queryParams.get('phone_number') || '',
    return_date: queryParams.get('return_date') || '',  // for URL 2
    return_time: queryParams.get('return_time') || '',  // for URL 2
    package_type_in_hours: queryParams.get('package_type_in_hours') || '',  // for URL 3
    ip_address: location?.ip || 8425,
  }), [queryParams, location]);

  useEffect(() => {
    // Scroll to top when the pathname changes
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem('tripData', JSON.stringify(tripDetails));
  }, [tripDetails]);

  return (
    <div>
      <Navbar />
      {/* <UpdateCabForm /> */}
      <MainCab tripDetails={tripDetails} />
      <CustomerFAQ> </CustomerFAQ>
      <Review />
      <DownloadSection />
      <Footer />
    </div>
  );
};

export default Cab;