import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// for new Order
const getDynamicStats = createAsyncThunk("dynamicStats", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/website/get_home_screen_details`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const getDynamicHeaderImage = createAsyncThunk("dynamicHeaderImage", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website/get_city_details`,data,config);
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {getDynamicStats, getDynamicHeaderImage}