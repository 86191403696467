import { createSlice } from "@reduxjs/toolkit"
import {getBlogCards, getBlog} from './BlogAction'


const blogCardSlice = createSlice({
    name: 'blogCard',
    initialState: { loading: true, error: false, blogCards: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBlogCards.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBlogCards.fulfilled, (state, action) => {
                state.blogCards = action?.payload?.data;
                state.loading = false;
                state.error = false;
            })
            .addCase(getBlogCards.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            });
    }
});

const blogSlice = createSlice({
    name: 'blog',
    initialState: { loading: true, error: false, blog: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBlog.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBlog.fulfilled, (state, action) => {
                state.blog = action?.payload?.data;
                state.loading = false;
                state.error = false;
            })
            .addCase(getBlog.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            });
    }
});


const blogCardAction = blogCardSlice.actions;
const blogAction = blogSlice.actions;

export {blogCardAction, blogCardSlice, blogAction, blogSlice};