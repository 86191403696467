import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import getSEOData from '../../../components/Seo';

const ForgotPassword = () => {
    const [formValues, setFormValues] = useState({
        email: '',
        capcha: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server, validate, etc.)
        console.log('Form Submitted:', formValues);
    };

    const seo = getSEOData('forgotPassword');

    return (
        <div className='login'>
            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />
                <meta name="keywords" content={seo.keywords} />
            </Helmet>
            <div className='login__container'>
                <div className='login__image-container'>
                    <img className='login__image' src='./images/agent-login-bg.png' alt='Login background' />
                </div>
                <div className='login__content'>
                    <div className='login__form-container'>
                        <img src='./images/logo-small.png' className='login__logo' alt='Logo' />
                        <form className='login__form' onSubmit={handleSubmit} autoComplete='on'>
                            <div className='login__heading'>Forgot Password</div>
                            <div className='login__subheading'>Complete the form below, and we will e-mail instructions for setting a new password.</div>
                            <div className='login__form-group'>
                                <div className='login__input-wrapper'>
                                    <input
                                        className='input-email'
                                        placeholder='Email ID'
                                        name='email'
                                        type='email'
                                        value={formValues.email}
                                        onChange={handleChange}
                                        autoComplete='email'
                                        required
                                    />
                                </div>
                                <div className='login__input-wrapper'>
                                    <input
                                        className='input-capcha'
                                        placeholder='Enter Capcha'
                                        name='capcha'
                                        type='text'
                                        value={formValues.capcha}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='login__form-actions--forgot'>
                                <button type='submit' className='login__button input-submit-button'>Sign In</button>
                            </div>
                        </form>
                        <div className='login__redirect login__redirect--secondary'>
                            <svg className='login__redirect-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-login-user"></use>
                            </svg>

                            <div className='login__redirect-links'>
                                <div className='login__redirect-link'>
                                    Are you an Member? <a href='/member-login'>Login Here</a>
                                </div>
                                <div className='login__redirect-para'>You will be taken to the member interface.</div>
                            </div>
                        </div>
                        <div className='login__redirect login__redirect--secondary'>

                            <svg className='login__redirect-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-login-user"></use>
                            </svg>

                            <div className='login__redirect-links'>
                                <div className='login__redirect-link'>
                                    Are you an Agent? <a href='/agent-login'>Login Here</a>
                                </div>
                                <div className='login__redirect-para'>You will be taken to the Agent interface.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;