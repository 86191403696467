import React from 'react'
import { useNavigate } from 'react-router'

const RecentBlogCard = ({card}) => {
  const navigate = useNavigate();

  return (
    <div className='recentBlogCard' onClick={()=>{navigate(`/blog/${card?.id}`)}}>
        <img className='recentBlogCard__img' src={card?.images} alt='blog'></img>
    </div>
  )
}

export default RecentBlogCard