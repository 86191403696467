import React from 'react';
import Form from './Form';

const Main = () => {
  return (
    <main className='home-main'>
      <div className='home-main__box'>
        <div className='home-main__box--left'>
          <img
            src="/images/pulpit-man-larger.png"
            srcSet="/images/pulpit-man-medium.png 600w, /images/pulpit-man-larger.png 1200w"
            sizes="(max-width: 600px) 100vw, 1200px"
            alt="Men showing pulpit logo and pulpit offers"
            className="home-main__img--pulpit"
          />
          <h1 className='home-main__heading'>
            Your Cab is a <span className='home-main__heading--highlight'>Click Away</span>
          </h1>
          <p className='home-main__para paragraph'>
            Need a ride? Let Pulpit be at your service, a one-stop solution for every type of cab booking. We offer riders several options with in-city oneway taxi booking, outstation cab booking, and hourly rentals. Your ride awaits at the Pulpit’s convenient rider application. All it takes is just one click.
          </p>
        </div>
        <div className='home-main__box--right'>
          <Form />
          <img src="./images/car-larger.png" alt='showing car illustrator' className='home-main__img--car' />
        </div>
      </div>
    </main>
  );
}

export default Main;