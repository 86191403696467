import React,{useEffect,useState} from 'react'
import CabCard from '../../../components/Cards/CabCard'
import BookCabForm from '../BookCabForm'
import PaymentCard from '../PaymentCard'

const BookCabMain = () => {
  const [tripData, setTripData] = useState(null);

  useEffect(() => {
    // Step 1: Retrieve tripData from localStorage
    const savedTripData = JSON.parse(localStorage.getItem('tripData'));
    if (savedTripData) {
      setTripData(savedTripData);
    }
  }, []);

  return (
    <div className="book-cab-main">
      <div className='book-cab-main__container'>
      <div className="book-cab-main__content">
        <CabCard showPrice={false} cab={tripData?.cab} isWidth={true} />
        <BookCabForm/>
      </div>
      <div className="book-cab-main__payment-section">
        <PaymentCard tripData={tripData}/>
      </div>
      </div>
    </div>
  )
}

export default BookCabMain