import { createSlice } from "@reduxjs/toolkit";
import { getCabs, bookNow, createOrder, applyCoupon, getCustomerFAQ } from "./CabAction";

const cabSlice = createSlice({
    name: 'cab',
    initialState: { 
        loading: true, 
        error: false, 
        cabs: [], 
        visitorEntryId: '', 
        web_trip_unique_id: '', 
        distance: '',
        bookingSuccess: false, 
        orderSuccess: false,
        order:{},
        couponApplied: {},
        couponAppliedSuccess: false,
        couponAppliedError: false,
        customerFAQ:[]
    },
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
        clearBookingSuccess: (state) => {
            state.bookingSuccess = false;
        },
        clearOrderSuccess: (state) => {
            state.orderSuccess = false;
        },
        clearCouponAppliedError: (state) => {
            state.couponAppliedError = false;
        },
        clearCouponAppliedSuccess: (state) => {
            state.couponAppliedSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder
            // getCabs cases
            .addCase(getCabs.pending, (state) => {
                state.loading = true;
                state.cabs = [];
            })
            .addCase(getCabs.fulfilled, (state, action) => {
                state.cabs = action?.payload?.data?.data;
                state.visitorEntryId = action?.payload?.data?.visitorEntryId;
                state.web_trip_unique_id = action?.payload?.data?.web_trip_unique_id;
                state.distance = action?.payload?.data?.distance;
                state.loading = false;
                state.error = false;
            })
            .addCase(getCabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.cabs = [];
            })
            
            // bookNow cases
            .addCase(bookNow.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookNow.fulfilled, (state, action) => {
                state.loading = false;
                state.bookingSuccess = true;
                state.error = false;
            })
            .addCase(bookNow.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.bookingSuccess = false;
            })

            // createOrder cases
            .addCase(createOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.orderSuccess = true;
                state.order= action?.payload?.data
                state.error = false;
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.orderSuccess = false;
            })

            // applyCoupon cases
            .addCase(applyCoupon.pending, (state) => {
                state.loading = true;
            })
            .addCase(applyCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.couponApplied = action?.payload?.data;
                state.error = false;
                state.couponAppliedSuccess= true;
            })
            .addCase(applyCoupon.rejected, (state, action) => {
                state.loading = false;
                state.couponApplied = {};
                state.couponAppliedError = true;
                state.couponAppliedSuccess= false;
            })

            // Customer FAQ
            .addCase(getCustomerFAQ.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCustomerFAQ.fulfilled, (state, action) => {
                state.loading = false;
                state.customerFAQ = action?.payload?.data?.data;
                state.error = false;
            })
            .addCase(getCustomerFAQ.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    }
});

const cabAction = cabSlice.actions;

export { cabAction, cabSlice };