import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// for new Order
const getUserLocation = createAsyncThunk("userLocation", async (data,{rejectWithValue}) => {
    try {
        const response = await axios.get('https://ipinfo.io/json');
        return response;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {getUserLocation}