import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { applyCoupon, createOrder } from '../../../store/Cab/CabAction';
import { cabAction } from '../../../store/Cab/CAbReducer';

const PaymentCard = ({ tripData }) => {
  const [paymentOption, setPaymentOption] = useState('complete'); // 'complete' or 'partial'
  const [couponCode, setCouponCode] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [offerAmount, setOfferAmount] = useState(0);
  const finalAmount = Number(parseInt(tripData?.cab?.total));
  const partialAmount = Number(parseInt(tripData?.cab?.total*0.2));
  const subAmount = Number(parseInt(tripData?.cab?.subtotal));
  const taxAmount = Number(parseInt(tripData?.cab?.gst));

  const dispatch = useDispatch();
  const { couponApplied, couponAppliedSuccess, visitorEntryId, couponAppliedError, orderSuccess, order } = useSelector((state) => state.cab);

  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  useEffect(() => {
    if (couponAppliedSuccess) {
      setIsCouponApplied(true);
      setOfferAmount(parseInt(couponApplied?.discount));
      dispatch(cabAction.clearCouponAppliedSuccess());
    }

    if (couponAppliedError) {
      alert('Invalid Coupon Code');
      dispatch(cabAction.clearCouponAppliedError());
    }
  }, [couponAppliedError, couponApplied,dispatch, couponAppliedSuccess]);

  useEffect(()=>{
     if(orderSuccess){
      const options = {
        key: order.key_id, 
        amount: order.amount, 
        currency: "INR",
        name: order.name,
        description: "Pulpit Cab Booking", 
        order_id: order.order_id, 
        handler: function (response) {
          alert(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
        },
        prefill: {
          name: order.name, 
          email: order.email, 
          contact: order.contact 
        },
        theme: {
          color: "#21A5A0" 
        }
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
  
      rzp.on('payment.failed', function (response) {
        alert(`Payment failed. Error: ${response.error.description}`);
      });

      dispatch(cabAction.clearOrderSuccess());
     }
  },[orderSuccess,order,dispatch])

  const handleCouponApply = () => {
    const data = {
      visitorEntryId: visitorEntryId,
      coupon_for: tripData?.trip_type===3?"OutStation":tripData?.trip_type===2?"Rental":"Local",
      code: couponCode,
      grand_total: parseInt(tripData?.cab?.total)
    }
    dispatch(applyCoupon(data));
  };

  const handleCouponRemove = () => {
    setIsCouponApplied(false);
    setOfferAmount(0);
    // setFinalAmount(paymentOption === 'partial' ? finalAmount * 0.2 : finalAmount); // Reset amount based on payment option
  };

  const handlePay = () => {
    const savedTripData = JSON.parse(localStorage.getItem('tripData'));
    console.log(partialAmount)
    if(savedTripData.first_name&&savedTripData.last_name&&savedTripData.email&&savedTripData.phone_number){
       const data={
        visitorEntryId: visitorEntryId,
        first_name:savedTripData.first_name,
        last_name:savedTripData.last_name,
        email:savedTripData.email,
        phone_number:"+91"+savedTripData.phone_number,
        payment_amount:(finalAmount-offerAmount)*100,
        advance_booking:(paymentOption==='complete'?100:20),
        advance_booking_amount:(paymentOption==='complete'?finalAmount-offerAmount:partialAmount)*100,
        GST: Number(parseInt(tripData?.cab?.gst_percentage)),
        GSTCalculate:Number(parseInt(tripData?.cab?.gst)),
        is_full_payment:paymentOption==='complete'?1:0
       }
       dispatch(createOrder(data))
    }else{
      alert('Please fill form');
    }
  };

  return (
    <div className="payment-card">
      <h2 className='payment-card__heading'>Payment Details</h2>

      {/* Payment Option */}
      <div className="payment-card__options">
        <label className='payment-card__label'>
          <input
            type="radio"
            value="complete"
            className='payment-card-radio'
            checked={paymentOption === 'complete'}
            onChange={handlePaymentOptionChange}
          />
          <div className='payment-card__para'>
            Make full payment now
          </div>
          <div className='payment-card__amount2'>
            ₹{finalAmount}
          </div>
        </label>
        <label className='payment-card__label'>
          <input
            type="radio"
            value="partial"
            className='payment-card-radio'
            checked={paymentOption === 'partial'}
            onChange={handlePaymentOptionChange}
          />
          <div className='payment-card__para'>
            Make 20% payment now
            <span>Pay remaining to the driver</span>
          </div>
          <div className='payment-card__amount2'>₹{partialAmount}</div>
        </label>
      </div>

      {/* Coupon Application */}
      <div className="payment-card__coupon">
        {!isCouponApplied ? (
          <>
            <input
              type="text"
              value={couponCode}
              placeholder="Enter coupon code"
              className='payment-card__coupon-input'
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button className="payment-card__coupon-button" onClick={handleCouponApply}>Apply</button>
          </>
        ) : (
          <div className="payment-card__coupon-applied">
            <span className='payment-card__para'>Coupon Applied</span>
            <button onClick={handleCouponRemove} className='payment-card__coupon-remove'>{couponCode} ✖</button>
          </div>
        )}
      </div>

      <div className="payment-card__total--sub">
        <div className='payment-card__para'>Base Amount:</div>
        <div className='payment-card__amount'>₹{subAmount}</div>
      </div>
      <div className="payment-card__total--sub">
        <div className='payment-card__para'>Tax:</div>
        <div className='payment-card__amount'>₹{taxAmount}</div>
      </div>

      {/* Offer Details */}
      {isCouponApplied && (
        <div className="payment-card__offer">
          <p className='payment-card__para'>Offer Applied:</p>
          <div className='payment-card__amount'>₹{offerAmount}</div>
        </div>
      )}

      {/* Total Amount */}
      {paymentOption==='partial'&&<div className="payment-card__total">
        <div className='payment-card__para'>20% Amount:</div>
        <div className='payment-card__amount'>₹{partialAmount}</div>
      </div>}

      <div className="payment-card__total">
        <div className='payment-card__para'>Total Amount: <span>inc. of tolls and taxes</span></div>
        <div className='payment-card__amount'>₹{finalAmount-offerAmount}</div>
      </div>

      {/* Pay Button */}
      <button onClick={handlePay} className="payment-card__pay-button">
        Pay ₹{paymentOption==='complete'?finalAmount-offerAmount:partialAmount}
      </button>
    </div>
  );
};

export default PaymentCard;