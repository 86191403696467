import React from 'react'
import Slider from '../../../components/Slider'
import ServiceCard from '../../../components/Cards/ServiceCard';
import { services } from '../../../components/data';

const ServiceSection = () => {
  return (
    <div className='serviceSection'>
        <h1 className='primary__heading--white margin__bottom-medium'>Our Services</h1>
        <Slider cards={services} cardWidth={35} cardMargin={2} component={ServiceCard} setWhite={true}></Slider>
    </div>
  )
}

export default ServiceSection