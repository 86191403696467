import './App.scss';
import { Routes, Route } from "react-router-dom";
import Home from './screens/Home';
import Cab from './screens/Cab';
import Partner from './screens/Partner';
import Business from './screens/Business';
import AboutUs from './screens/AboutUs';
import Blogs from './screens/Blogs';
import BlogPage from './screens/BlogPage';
import GetApp from './screens/GetApp';
import MemberLogin from './screens/Login/MemberLogin';
import AgentLogin from './screens/Login/AgentLogin';
import ForgotPassword from './screens/Login/ForgotPassword';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getUserLocation } from './store/UserLocation/UserLocationAction';
import { getDynamicHeaderImage } from './store/DynamicData/DynamicDataAction';
import { loadGoogleMapsScript, loadZohoChatScript } from './components/script';
import BookCab from './screens/BookCab';

function App() {
  const dispatch = useDispatch();
  const {  location } = useSelector((state) => state.location);

  useEffect(()=>{
    dispatch(getUserLocation({}));
},[dispatch]);

 useEffect(()=>{
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  loadGoogleMapsScript(apiKey);
  loadZohoChatScript();
 },[]);

  useEffect(()=>{
    if(location?.city){
      dispatch(getDynamicHeaderImage({ "city":location?.city}))
    }
  },[dispatch,location])

  return (
    <Routes>
      <Route path="/">
        <Route
            index
            element={
              <Home/>
            }
        />
      </Route>
      <Route path="/cabs">
        <Route
            index
            element={
                    <Cab/>
            }
        />
      </Route>
      <Route path="/bookCab">
        <Route
            index
            element={
                    <BookCab/>
            }
        />
      </Route>
      
      <Route path="/partner">
        <Route
            index
            element={
                    <Partner/>
            }
        />
      </Route>
      <Route path="/business">
        <Route
            index
            element={
                    <Business/>
            }
        />
      </Route>
      <Route path="/about-us">
        <Route
            index
            element={
                    <AboutUs/>
            }
        />
      </Route>
      <Route path="/blogs">
        <Route
            index
            element={
                    <Blogs/>
            }
        />
      </Route>
      <Route path="/blog/:id">
        <Route
            index
            element={
                    <BlogPage/>
            }
        />
      </Route>
      <Route path="/get-app">
        <Route
            index
            element={
                  <GetApp/>
            }
        />
      </Route>
      <Route path="/member-login">
        <Route
            index
            element={
                  <MemberLogin/>
            }
        />
      </Route>
      <Route path="/agent-login">
        <Route
            index
            element={
                  <AgentLogin/>
            }
        />
      </Route>
      <Route path="/forgot-password">
        <Route
            index
            element={
                  <ForgotPassword/>
            }
        />
      </Route>
    </Routes>
  );
}

export default App;