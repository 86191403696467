import React from 'react'
import { Link } from 'react-router-dom';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}


const BlogCard2 = ({data}) => {
  return (
    <Link className='blog-card2' to={`/blog/${data?.id}`}>
      <div className='blog-card2__image-container'>
        <img src={data?.images} alt='location' className='blog-card2__image'></img>
      </div>
      <div className='blog-card2__container'>
        <div className='blog-card2__details'>
          By &nbsp; <span className='blog-card2__author'>{data?.meta_author}</span>
          <span className='blog-card2__date'>{data?.created_at && formatDate(data.created_at)}</span>
        </div>
        <div className='blog-card2__description'>
          {data?.short_description}
        </div>
      </div>
    </Link>
  )
}

export default BlogCard2