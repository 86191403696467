import React, { useState,useRef } from 'react';
import PlacesAutocomplete from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LocalForm = () => {
  const [formValues, setFormValues] = useState({
    from: null,
    to: null,
    pickUpDate: '',
    pickUpTime: '',
    mobileNumber: ''
  });

  const pickUpDateInputRef = useRef(null);
  const pickUpTimeInputRef = useRef(null);

  const handleWrapperClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  const { location } = useSelector((state) => state.location);

  const today = new Date();
  const formattedToday = today.toLocaleDateString('en-CA');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const pickup_lat = formValues.from ? formValues.from.latitude : null;
    const pickup_long = formValues.from ? formValues.from.longitude : null;
    const drop_lat = formValues.to ? formValues.to.latitude : null;
    const drop_long = formValues.to ? formValues.to.longitude : null;

    const tripData = {
      trip_type: 1,
      round_trip: 0,
      pickup: formValues.from?.label || '',
      drop: formValues.to?.label || '',
      pickup_lat,
      pickup_long,
      drop_lat,
      drop_long,
      pickup_city: formValues.from?.city || formValues.from?.label || '',
      drop_city: formValues.to?.city || formValues.to?.label || '',
      package_type_in_hours: "",
      trip_date: formValues.pickUpDate,
      trip_time: formValues.pickUpTime,
      phone_number: formValues.mobileNumber,
      ip_address: location?.ip || '8425',
    };

    navigate(`/cabs?trip_type=${tripData?.trip_type}&round_trip=${tripData?.round_trip}&pickup=${tripData?.pickup}&drop=${tripData?.drop}&pickup_lat=${tripData?.pickup_lat}&pickup_long=${tripData?.pickup_long}&drop_lat=${tripData?.drop_lat}&drop_long=${tripData?.drop_long}&pickup_city=${tripData?.pickup_city}&drop_city=${tripData?.drop_city}&trip_date=${tripData?.trip_date}&trip_time=${tripData?.trip_time}&
      phone_number=${tripData?.phone_number}`);
  };


  const handlePlaceSelect = async (value, field) => {
    if (!value) {
      // Handle the case when the value is cleared
      setFormValues(prevValues => ({
        ...prevValues,
        [field]: null, // Clear the field value in formValues
      }));
      return; // Exit the function
    }

    try {
      const locationDetails = await getPlaceDetails(value.value.description);

      if (locationDetails) {
        setFormValues(prevValues => ({
          ...prevValues,
          [field]: {
            label: value.label,
            place_id: value.place_id, // Store the place_id if needed
            latitude: locationDetails.lat,
            longitude: locationDetails.lng,
            city: locationDetails.city,
          },
        }));
      } else {
        console.error('Location details could not be fetched.');
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const getPlaceDetails = async (address) => {
    try {
      const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address: address,
          key: API_KEY
        }
      });

      if (response && response.data && response.data.results && response.data.results[0] && response.data.results[0].geometry) {

        // Extract latitude and longitude
        const { lat, lng } = response.data.results[0].geometry.location;

        // Extract city from address components
        const addressComponents = response.data.results[0].address_components;
        const cityComponent = addressComponents.find(component => component.types.includes('locality'));

        return {
          lat,
          lng,
          city: cityComponent ? cityComponent.long_name : ''
        };
      }
      return null; // Make sure to return null if the expected data isn't found
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <div className='local-form'>
      <form onSubmit={handleSubmit}>

        <PlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          selectProps={{
            value: formValues.from ? { label: formValues.from.label } : null,
            onChange: (value) => handlePlaceSelect(value, 'from'),
            placeholder: 'From',
            isClearable: true,
            getOptionLabel: (e) => e.label || '',
            getOptionValue: (e) => e.place_id || '',
            styles: {
              control: (provided) => ({
                ...provided,
                padding: '5px',
                border: '1px solid #000',
                boxShadow: 'none',
                width: '100%',
                marginBottom: '2rem',
                fontSize: '1.4rem',
              }),
              input: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              menu: (provided) => ({
                ...provided,
                fontSize: '1.4rem',  // Match dropdown font size to input
                zIndex: 3
              }),
              option: (provided, state) => ({
                ...provided,
                fontSize: '1.4rem',  // Match font size of options
                backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            },
            components: {
              DropdownIndicator: () => null,  // Removes the dropdown arrow
              IndicatorSeparator: () => null, // Removes the separator
            },
          }}
        />


        <PlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          selectProps={{
            value: formValues.to ? { label: formValues.to.label } : null,
            onChange: (value) => handlePlaceSelect(value, 'to'),
            placeholder: 'To',
            isClearable: true,
            getOptionLabel: (e) => e.label || '',
            getOptionValue: (e) => e.place_id || '',
            styles: {
              control: (provided) => ({
                ...provided,
                padding: '5px',
                border: '1px solid #000',
                boxShadow: 'none',
                width: '100%',
                marginBottom: '2rem',
                fontSize: '1.4rem',
              }),
              input: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '1.4rem',
              }),
              menu: (provided) => ({
                ...provided,
                fontSize: '1.4rem',  // Match dropdown font size to input
                zIndex: 3
              }),
              option: (provided, state) => ({
                ...provided,
                fontSize: '1.4rem',  // Match font size of options
                backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            },
            components: {
              DropdownIndicator: () => null,  // Removes the dropdown arrow
              IndicatorSeparator: () => null, // Removes the separator
            },
          }}
        />



        <div className='local-form__date-time'>
          <div className='input-wrapper' onClick={() => handleWrapperClick(pickUpDateInputRef)}>
            <input
              type='date'
              className='input-date'
              name='pickUpDate'
              ref={pickUpDateInputRef}
              min={formattedToday}
              value={formValues.pickUpDate}
              onChange={handleChange}
              required
            />
            <label className={`input-placeholder ${formValues.pickUpDate ? 'input-hidden' : ''}`}>Pick Up Date</label>
            <svg className='input-icon' aria-hidden="true">
              <use href="sprite.svg#icon-calendar"></use>
            </svg>
          </div>
          <div className='input-wrapper' onClick={() => handleWrapperClick(pickUpTimeInputRef)}>
            <input
              type='time'
              className='input-time'
              name='pickUpTime'
              value={formValues.pickUpTime}
              ref={pickUpTimeInputRef}
              onChange={handleChange}
              required
            />
            <label className={`input-placeholder ${formValues.pickUpTime ? 'input-hidden' : ''}`}>Pick Up Time</label>
            <svg className='input-icon' aria-hidden="true">
              <use href="sprite.svg#icon-clock"></use>
            </svg>
          </div>
        </div>

        <input
          className='input-phone'
          placeholder='Mobile Number'
          name='mobileNumber'
          type='tel'
          value={formValues.mobileNumber}
          onChange={handleChange}
          pattern="[0-9]{10}"
          maxLength="10"
          required
        />

        <button type='submit' className='input-submit-button'>Search</button>
      </form>
      <p className='local-form__paragraph' style={{ color: "red" }}>Local Cabs are unavailable for distances exceeding 80 kilometers.</p>
    </div>
  );
};

export default LocalForm;