import React,{useState} from 'react'
import Slider from '../../components/Slider'
import ClientCard from '../../components/Cards/ClientCard'
import { mediaCoverage, trustedByTheseCompanies } from '../../components/data'


const Client = () => {
  const [option, setOption] = useState(1);

  const selectOption = (val)=>{
    setOption(val);
  }

  return (
    <div className='client'>
       <h1 className='primary__heading margin__bottom-medium'>Our Clients</h1>
       <div className='client__option-box'>
         <div className={option===1?'client__option-active client__option':'client__option'} onClick={()=>{selectOption(1)}}>Trusted by these Companies</div>
         <div className={option===2?'client__option-active client__option':'client__option'} onClick={()=>{selectOption(2)}}>Media Coverage</div>
       </div>
       <div className='client__container'>
       <Slider cards={option===1?trustedByTheseCompanies:mediaCoverage} cardWidth={13} cardMargin={2} component={ClientCard}></Slider>
       </div>
    </div>
  )
}

export default Client